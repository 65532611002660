<template>
  <div>
    <Banner
      bannerTitle="金箱数云平台系统"
      bannerHref="https://pt.goldwms.cn"
      backImage="goldmatrix/banner.png"
    ></Banner>
    <MediumContain
      :imgUrl="topBox.imgUrl"
      :h-title="topBox.title"
      :h-content="topBox.content"
      style="padding: 40px 0"
    ></MediumContain>
    <div class="platform">
      <CommonTitle
        h-title="平台优势"
        h-descrip="Platform advantages"
      ></CommonTitle>
      <div class="platform-box">
        <div class="top">
          <div class="top-left">
            <img src="../../assets/goldmatrix/platformBox.png" alt="" />
          </div>
          <div class="top-right">
            <PlatBox
              title="统一认证"
              content="实现企业认证、员工实名，平台认证等服务"
              class="platBox"
            ></PlatBox>
            <PlatBox
              title="智能监管"
              content="服务货品流通过程，物码合一  全流程追溯"
              class="platBox"
            ></PlatBox>
            <PlatBox
              title="业务闭环"
              content="平台流通货品的所有交易行为都是闭环交割"
              class="platBox"
            ></PlatBox>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-left">
            <PlatBox
              title="多维可视"
              content="实现货品数据、业务场景、权属责任可视化"
              class="platBox"
            ></PlatBox>
            <PlatBox
              title="数字化"
              content="业务数字化、数据可信采集提升企业运营效率"
              class="platBox"
            ></PlatBox>
            <PlatBox
              title="金融及风控"
              content="数据的监督管理，为企业提供数字金融支撑"
              class="platBox"
            ></PlatBox>
          </div>
          <div class="bottom-right">
            <img src="../../assets/goldmatrix/platComputer.png" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="platform-box">
        <TypeContain
          v-for="item in centerInfo"
          :key="item.title"
          :content="item"
        ></TypeContain>
      </div> -->
    </div>
    <div class="feature">
      <CommonTitle
        h-title="功能特色"
        h-descrip="Functional features"
      ></CommonTitle>
      <div class="feature-box">
        <div class="left">
          <img src="../../assets/goldmatrix/platFeature.png" alt="" />
        </div>
        <div class="right">
          <FeatureBox
            v-for="item in features"
            :key="item.title"
            :title="item.title"
            :content="item.content"
            class="featureBox"
          ></FeatureBox>
          <div class="horizontalLine"></div>
          <div class="verticalLine"></div>
        </div>
        <!-- <Box
          v-for="item in features"
          :key="item.title"
          :imgUrl="item.imgUrl"
          :title="item.title"
          :content="item.content"
          height="359px"
        ></Box> -->
      </div>
    </div>
    <div class="product">
      <CommonTitle h-title="产品示列" h-descrip="Product list"></CommonTitle>
      <div class="product-box">
        <img :src="require('@/assets/goldmatrix/product-img.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
import MediumContain from "@/components/contain/mediumContain.vue";
import CommonTitle from "@/components/contain/commonTitle.vue";
import TypeContain from "@/components/contain/typeContain.vue";
import Box from "@/components/box";
import PlatBox from "./platBox";
import FeatureBox from "./featureBox";
export default {
  components: {
    Banner,
    MediumContain,
    CommonTitle,
    TypeContain,
    Box,
    PlatBox,
    FeatureBox,
  },
  data() {
    return {
      topBox: {
        imgUrl: "goldmatrix/company-image.png",
        title: "金箱数云",
        content:
          "面向贵金属产业的货品数字化服务平台，集合物联网、大数据和数字存证等技术，为企业内货管、企业间\n货转、银企间货品监管提供有力业务支持，并可以通过开放能力为企业自有ERP、营收系统等提供数据和业务流对接。",
      },
      centerInfo: [
        {
          imgUrl: "goldmatrix/store-center-1.png",
          title: "统一认证",
          info: "实现企业认证、员工实名，平台认证等服务",
        },
        {
          imgUrl: "goldmatrix/store-center-2.png",
          title: "智能监管",
          info: "服务货品流通过程，物码合一  全流程追溯",
        },
        {
          imgUrl: "goldmatrix/store-center-3.png",
          title: "多维可视",
          info: "实现货品数据、业务场景、权属责任可视化",
        },
        {
          imgUrl: "goldmatrix/store-center-4.png",
          title: "业务闭环",
          info: "平台流通货品的所有交易行为都是闭环交割",
        },
        {
          imgUrl: "goldmatrix/store-center-5.png",
          title: "数字化",
          info: "业务数字化、数据可信采集提升企业运营效率",
        },
        {
          imgUrl: "goldmatrix/store-center-6.png",
          title: "金融及风控",
          info: "数据的监督管理，为企业提供数字金融支撑",
        },
      ],
      features: [
        {
          imgUrl: require("@/assets/goldmatrix/features-2.png"),
          title: "业务授权 | 安全审核",
          content: `企业提出入驻申请后需要平台通过审核，并且根据企业的提交的入驻资料、业务行
                  为、经营范围等多方面信息对企业开通对应的业务权限
                  企业提报的所有基础档案类数据都需要平台进行审核确认符合安全规定后才可在业务中使用`,
        },
        {
          imgUrl: require("@/assets/goldmatrix/features-4.png"),
          title: "数据统计 | 数据可视化",
          content: `多角度统计平台入驻企业、流通货品、风险对象的数据，提供如大屏数据可视化、
                  企业透视、货品透视、数据报表系统等多种数据展示、分析功能模块`,
        },
        {
          imgUrl: require("@/assets/goldmatrix/features-3.png"),
          title: "货品管控 | 流转监督",
          content: `货品上线需要提交真实信息，经平台审核完成后才能投入流通，货品上线后在进行
            各个业务操作的过程中，系统会记录货品的实时信息，并做整理归档`,
        },

        {
          imgUrl: require("@/assets/goldmatrix/features-5.png"),
          title: "可视化存证 | 风控支持",
          content: `所有业务操作，按人、场、物、事做关联数据的全记录，并云端备份，所有数据均
                    可在平台提供的各个关联功能进行查询；并且基于平台存储的数据及业务中的风险
                    对象，平台提供风控支持，可对风险对象指定对应的风控规则，风控释放等操作，
                    为平台及企业减少业务中风险`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.platform {
  background: #f5f5f5;
  overflow: hidden;
  padding-bottom: 40px;

  &-box {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-content: space-between;
    gap: 80px;
  }
}
.feature {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
  background: url("../../assets/goldmatrix/feature-back.png") no-repeat
    center/cover;
}
.product {
  background: #f5f5f5;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 40px;

  &-box {
    display: flex;
    justify-content: center;

    img {
      width: 1200px;
    }
  }
}
.platform-box {
  width: 1200px;
  height: 730px;
  background: #fff;
  display: block;
  .top {
    width: 1200px;
    height: 385px;
    display: flex;
    justify-content: space-between;
    .top-left {
      width: 600px;
      height: 385px;
      img {
        width: 600px;
        height: 345px;
      }
    }
    .top-right {
      width: 600px;
      height: 385px;
      .platBox {
        margin-top: 40px;
        margin-left: 40px;
      }
    }
  }
  .bottom {
    width: 1200px;
    height: 345px;
    display: flex;
    justify-content: space-between;
    .bottom-left {
      width: 600px;
      height: 345px;
      .platBox {
        margin-top: 40px;
        margin-left: 40px;
      }
      .platBox:nth-child(1) {
        margin-top: 0;
      }
    }
    .bottom-right {
      width: 600px;
      height: 345px;
    }
  }
}
.feature-box {
  width: 1200px;
  height: 420px;

  display: flex;
  justify-content: space-between;
  .left {
    width: 600px;
    height: 420px;
    img {
      width: 600px;
      height: 420px;
    }
  }
  .right {
    width: 600px;
    height: 420px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    position: relative;
    .horizontalLine {
      width: 560px;
      height: 0px;
      border: 1px solid #e2e4ea;
      opacity: 1;
      position: absolute;
      top: 196px;
      left: 40px;
    }
    .verticalLine {
      width: 0px;
      height: 420px;
      border: 1px solid #e2e4ea;
      opacity: 1;
      position: absolute;
      left: 313px;
    }
    .featureBox {
      margin-left: 40px;
    }
  }
}
.feature-box::after {
  width: 1px;
  content: "";
}
</style>
