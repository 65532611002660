<template>
  <div>
    <div class="featureBox">
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "content"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.featureBox {
  width: 252px;
  .title {
    font-size: 16px;
    color: #ea9052;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .content {
    color: #333333;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
