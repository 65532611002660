<template>
  <div class="contain">
    <div class="content">
      <div class="leftImg">
        <img :src="require(`@/assets/${imgUrl}`)" alt="" />
      </div>
      <div class="rightContent">
        <div class="title">{{ hTitle }}</div>
        <div class="body">
          {{ hContent }}
        </div>
      </div>
      <img
        class="icon"
        :src="require(`@/assets/goldmatrix/arrow.png`)"
        alt=""
        v-if="icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    hTitle: {
      type: String,
      default: "",
    },
    hContent: {
      type: String,
      default: "",
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 760px 1fr;
  gap: 40px;
  position: relative;

  .leftImg {
    height: 360px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
  .rightContent {
    padding: 40px 40px 40px 0;
  }

  .title {
    font-size: 24px;
    font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
    font-weight: bold;
    color: #ea9052;
    padding-bottom: 23px;
    border-bottom: 1px solid #666666;
  }
  .body {
    margin-top: 27px;
    font-size: 14px;
    line-height: 22px;
    font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
    font-weight: normal;
    color: #666666;
  }
  .icon {
    position: absolute;
    width: 66px;
    height: 48px;
    right: 40px;
    bottom: 40px;
  }
}
</style>
