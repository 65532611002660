<template>
  <div class="contain">
    <img :src="require('@/assets/' + content.imgUrl)" alt="" />
    <div class="title">{{ content.title }}</div>
    <div class="line"></div>
    <div class="descrip">{{ content.info }}</div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 347px;
  height: 380px;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  user-select: none;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.16);
    border: 1px solid #ea9052;
    transform: translateY(-3px);

    .title {
      color: #ea9052;
    }
  }

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 76px;
  }
  .title {
    font-size: 24px;
    font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
    font-weight: bold;
    color: #333333;
    margin-bottom: 40px;
    transition: 0.3s;
  }
  .line {
    width: 40px;
    height: 5px;
    background: #ea9052;
    margin-bottom: 40px;
  }
  .descrip {
    font-size: 16px;
    font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
    font-weight: normal;
    color: #666666;
  }
}
</style>
