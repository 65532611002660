<template>
  <div
    class="container"
    :style="{
      backgroundImage: 'url(' + require('@/assets/' + backImage) + ')',
    }"
  >
    <div class="content">
      <div class="default site" v-if="model == 'default'">
        <div class="title">{{ bannerTitle }}</div>
        <div class="btn">
          <a :href="bannerHref" target="_blank">点击进入</a>
        </div>
      </div>
      <div class="slot site" v-else-if="model == 'slot'">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    model: {
      type: String,
      default: "default",
    },
    bannerTitle: {
      type: String,
      default: "",
    },
    bannerHref: {
      type: String,
      default: "#",
    },
    backImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.content {
  width: 1200px;
  height: 400px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 32px;
    font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
    font-weight: bold;
    color: #ea9052;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 40px;
  }
  .btn {
    a {
      display: inline-block;
      background: #ea9052;
      border-radius: 6px;
      width: 134px;
      height: 40px;
      line-height: 40px;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 0 10px #ea9052;
      }
    }
  }
}
</style>
