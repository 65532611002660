<template>
  <div>
    <div class="box">
      <img src="../../../assets/goldmatrix/platOne.png" alt="" />
      <div class="title">{{ title }}</div>
      <div class="line"></div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "content"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 304px;
  height: 75px;
  img {
    width: 24px;
    height: 24px;
    float: left;
  }
  .title {
    width: 125px;
    height: 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #333333;
    margin-left: 35px;
  }
  .line {
    width: 40px;
    height: 5px;
    background: #ea9052;
    margin: 20px 0 10px 0;
  }
  .content {
    width: 304px;
    height: 16px;
    font-size: 14px;
    color: #666666;
  }
}
</style>
